















import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Observer
@Component
export default class extends Vue {
  @Prop() value!: any
  @Prop({ default: '' }) placeholder!: string
  @Prop({ default: () => [] }) rules!: any
}
